

.home__slider{
  background-color: #F3F3F3;
  .carousel__content{
    h1, h2{
      font-family: $text_black;
      color: $primary;
      margin-bottom: 2rem;
      position: relative;
      text-transform: uppercase;
      font-size: 30px;
      &::after{
        content: "";
        display: block;
        height: 8px;
        width: 48px;
        background: $primary;
        border-radius: 4px;
        margin-top: 10px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    // min-height: 500px;
    // max-height: 625px;
    .carousel_wrapper{
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 625px;

    }

    .slide_img {
      -webkit-clip-path: url(#shape);
      clip-path: url(#shape);
      object-fit: cover;
    }
    .carousel__content{
      padding: 3rem;
      @include media-breakpoint-up(xl) {
        padding-left: 6rem;

      }
      display: flex;
      justify-content: center;
      flex-direction: column;

    }
    .carousel__image{
      height: 625px;
      max-height: 625px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      &::before{
        content: "";
        height:600px;
        width: 100px;
        background-color: rgba($primary, .7);
        position: absolute;
        left: 85px;
        top:30px;
        border-radius: 50px;
        // -webkit-clip-path: url(#shape);
        // clip-path: url(#shape);
        transform: rotate(-17deg);
      }
    }
  }
  @include media-breakpoint-only(md) {
    .carousel__image, .carousel_wrapper{
      height: 400px;
    }
    .slide_img{
      height: 100%;
    }
  }
  @include media-breakpoint-down(md) {
    .carousel_wrapper{
      position: relative;
      svg{
        display: none;
      }
      .carousel__content{

        padding: 2rem;
      }
      .carousel__image{
        height: 400px;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .home_carousel{
    margin-bottom: 0;
  }
}

.fast__links{
  font-family: $text_medium;
  text-align: center;
  list-style: none;
  padding-left: 0;
  @include media-breakpoint-up(md) {
    display: inline-flex;
    grid-gap:20px;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

  }
  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
  }
  @include media-breakpoint-only(md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
  }
  .link{
    @include media-breakpoint-up(md) {
      width: 200px;
    }
    a{
      text-transform: uppercase;
      overflow: hidden;
      position: relative;
      color: inherit;
      text-decoration: none;
      padding: 1rem;
      padding-top: 3rem;
      padding-bottom: 2rem;
      background-color: white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      min-height: 180px;
      @include media-breakpoint-down(md) {
        min-height: 100%;
        font-size: 14px;
      }
      &::before{
        content: "";
        height: 8px;
        width: 100%;
        background-color: $primary;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &__title{
      display: block;
      p{
        margin-bottom: 0;
      }
    }
    .icon{
      position: relative;
      img{
        position: relative;
      }
    }
    svg{
      position: absolute;
      transform: rotate(-45deg);
      left: 50%;
      top: -8px;
      margin-left: -17px;
      rect{
        fill: rgba($primary, .6);
      }
    }
    &:hover{
      z-index: 2;
    }
  }
}


.news, .agenda, .publications{
  padding: 4rem 0;
  @include media-breakpoint-down(lg) {
    padding: 2rem 0;
  }
}


section{
  h2{
    text-transform: uppercase;
    position: relative;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}

.agenda{
  background-color: #F3F3F3;
  .blog-latest-entries{
    margin-top: 20px;
    display: grid;
    gap: 1rem;
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .event{
    position: relative;
    border-radius: 10px;
    img{
      border-radius: 10px;
    }
    &__content{
      position: absolute;
      bottom: 20px;
      right: 20px;
      left: 20px;
      text-align: center;
      background-color: rgba($secondary, .95);
      color: white;
      padding: 2rem 1rem;
      border-radius: 10px;
      h3{
        text-transform: uppercase;
        margin-top: 10px;
      }
      a{
        color: inherit;
        text-decoration: none;
      }
      .event__date{
        color: $primary;
        .date-range{
          display: flex;
          flex-wrap: wrap;
          gap: .2rem;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 18px;
        }
      }
    }
  }
}



.wrapper {
  display: flex;
  justify-content: center;
}

.news{
  @include media-breakpoint-up(md) {
    .blog-latest-entries {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr .7fr 1.3fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
      grid-template-areas:
        "featured featured post_1"
        "featured featured post_2";
    }
    article:nth-child(1) {
      grid-area: featured;
      img{
        min-width: 100%;
        min-height: 100%;
        object-fit: contain;
      }
      .post__content{
        position: absolute;
        padding: 30px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($primary, .8);
        &, *{
          color: white;
        }
      }
    }
    article:nth-child(2) { grid-area: post_1; }
    article:nth-child(3){ grid-area: post_2; }
    article{
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      h3{
        font-size: 22px;
        a{
          display: block;
        }
      }
      a{
        color: inherit;
        text-decoration: none;
      }
      .post__categories{
        display: flex;
        flex-wrap: wrap;
        gap: .25rem;
        margin-bottom: 10px;
        a{
          font-weight: bold;
        }
        margin-left: 0;
      }
      &:not(:first-child){
        background-color: $secondary;
        padding: 3rem;
        color: white;
        display: flex;
        align-items: center;
        .post__content{
          min-width: 100%;
        }
        h3{
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid lighten($secondary, 40%);
        }
      }
      &:focus-within {
        outline: solid 3px var(--bs-blue);
      }
    }

  }
  @include media-breakpoint-down(md) {
    .blog-latest-entries{
      article{
        background-color: $secondary;
        margin-bottom: 1rem;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        a{
          text-decoration: none;
        }
        .post__content{
          padding: 1rem;
          &, a{
            color: white;
          }
        }
        &:first-child{
          .post__content{
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba($secondary, .95);
          }
        }
      }
    }
  }
  @include media-breakpoint-only(md) {
    article :nth-child(1){
      a{
        display: block;
        height: 100%;
      }
      .blog-visual{
        height: 100%;
        img{
          object-fit: cover;
        }
      }
    }
  }
}


.top__page{
  background-color: darken(white, 5);
  @include media-breakpoint-up(md) {
    padding: 3rem 0;
    margin-bottom: 4rem;
  }
  @include media-breakpoint-down(sm) {
    padding: 1.5rem 0;
    margin-bottom: 2rem;
  }
  h1{
    text-transform: uppercase;
    position: relative;
    font-size: 30px;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}


.mag__item{
  @include media-breakpoint-up(md) {
    background-color: rgba($primary, .3);
    display: flex;
    padding: 2rem;
    position: relative;

    &::after{
      content: "";
      height: 100px;
      background-color: white;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .mag__cover {
      max-width: 300px;
    }
    .mag__informations, .mag__cover{
      position: relative;
      z-index: 1;
    }
    .mag__informations{
      padding: 2rem;
      margin-top: -100px;
      align-self: center;
    }
    .mag__title{
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .mag__links{
      margin-top: 10px;
      gap: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      a{
        background: #FFFFFF;
        box-shadow: 0 2px 24px 0 rgba(180,180,180,0.50);
        border-radius: 27px;
        display: inline-block;
        color: inherit;
        text-decoration: none;
        padding: 1rem 2rem;
        font-weight: bold;
        font-size: 14px;
        width: auto;
        text-align: center;
        &:hover{
          color: white;
          background-color: $primary;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    align-items: center;
    border: 2px solid $primary;
    padding: 1rem;
    border-radius: 20px;
    background-color: rgba($primary, .3);
    a{
      display: block;
      background-color: white;
      border: 1px solid #eee;
      padding: 1rem;
    }
    .mag__cover {
      text-align: center;
    }
    .mag__informations{
      text-align: center;
      padding: 1rem;
      .mag__title{
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
      }
    }
    .mag__links{
      a{
        background-color: $primary;
        color: white;
        text-decoration: none;
        border-radius: 30px;
        border:0;
        margin-bottom: 10px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    .mag__links{
      display: flex;
      flex-direction: column;
      gap:1rem;
    }
  }
}

.page__image{
  margin-bottom: -6rem;
  min-width: 100%;
  height: auto;
  @include media-breakpoint-down(lg) {
    margin-bottom: 0rem;
  }
}

.page_menu {
  background-color: $dark;
  padding: 1rem;
  position: sticky;
  top: 130px;
  &, a{
    color: white;
  }
  .page__title a {
    font-family: $text_medium;
    font-size: 18px;
    text-decoration: none;
  }
  ul{
    list-style-type: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 10px;
    &::before{
      content: "";
      width: 6px;
      height: 100%;
      background-color: $primary;
      border-radius: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
    }
    a{
      font-weight: bold;
      display: block;
      line-height: 2;
      text-decoration: none;
      transition: all .3s ease;
      &:hover{
        color: $primary;
      }
    }
  }
  .nav__item.active a {
    color: $primary;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
}

.wrapper{
  >div{
    min-width: 100%;
  }
  h2{
    text-transform: uppercase;
    position: relative;
    font-size: 30px;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.bg-primary{
  h2{
    &::after{
      background: white;
    }
  }
}


.gallery{
  display: grid;
  gap: 1rem;
  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.home_carousel{
  .slick-dots{
    bottom: 70px;
    li{
      button{
        &::before{
          color: $primary;
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          content: "\f111";
          font-size: 14px;
        }
        &:focus {
          outline: solid 1px var(--bs-blue);
        }
      }
    }
  }
  .slick-arrow{
    z-index: 999;
    &:focus {
      outline: solid 1px var(--bs-blue);
    }
    &::before{
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      color: $primary;
    }

    &.slick-next{
      right: 20px;
      &::before{
        content: "\f054";
      }
    }
    &.slick-prev{
      left: 20px;
      &::before{
        content: "\f053";
      }
    }
  }
}





//blog


.posts__wrapper{
  display: grid;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 300px;
    gap: 2rem;
  }
  .blog__list{
    display: grid;
    gap: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);

    }
    article{
      border: 1px solid #eee;
      padding: 1rem;
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      a{
        text-decoration: none;
      }
      h3{
        line-height: 1.2;
        font-size: 20px;
        a{
          color: inherit;
        }
      }
      .blog-visual{
        margin-bottom: 10px;
        img{
          max-width: 100%;
          height: auto;
        }
      }
      .post__categories{
        display: flex;
        flex-wrap: wrap;
        gap: .25rem;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 5px;
        font-size: 90%;
        font-weight: bold;
      }
    }
    .blog-back{
      grid-column: 1 / -1;
    }
  }
  .pagination{
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    grid-column: 1 / -1;
    gap: 1rem;
    a{
      text-decoration: none;
    }
  }
  aside{
    .plugin{
      margin-bottom: 1rem;
      background-color: darken(white, 2);
      padding: 20px;
      border: 1px solid $primary;
    }
    h3{
      text-transform: uppercase;
      font-size: 18px;
      font-family: $text_black;
      color: $primary;
    }
    ul{
      list-style-type: none;
      padding-left: 0;
      li:not(:last-child){
        border-bottom: 1px dashed darken(white, 10);
      }
      a{
        text-decoration: none;
        font-weight: bold;
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        color: inherit;
        padding: .5rem 0;
        transition: all .3s ease;
        span{
          display: none;
        }
        &:hover{
          color: $primary;
          transform: translateX(10px);
        }
      }
    }
  }
}

.post__footer{
  margin-bottom: 20px;
}

.app-blog{
  .post-detail{
    .post-detail-list{
      margin-bottom: 2rem;
      gap: 1rem;
      display: grid;
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      article{
        border: 1px solid #eee;
        padding: 10px;
        h3{
          a{
            color: inherit;
            text-decoration: none;
          }
        }

      }
      .blog-visual{
        margin-bottom: 10px;
        a{
          display: block;
        }
        img{
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
.post__categories{
  list-style: none;
  padding-left: 0;
  display: flex;
  gap: .25rem;
  margin-bottom: 10px;
  a{
    font-size: 80%;
    font-weight: bold;
    color: white;
    display: inline-flex;
    background-color: $primary;
    padding: 3px 6px;
    text-decoration: none;
    color: $dark;
  }
}
.plugin-blog{
  .see-all{
    margin-top: 10px;
    text-align: right;
    margin-bottom: 10px;
  }
}




.fobi-form{
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  padding: 2rem;
  background-color: darken(white, 2);
  gap: 1rem;
  .form-required-field-message-wrapper,
  .form-element-wrapper-votre_message,
  .form-textarea-wrapper,
  .form-button-wrapper{
    grid-column: 1/-1;
  }
  label{
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
  }
  .required-field{
    color: red;
  }
  .help-block{
    margin-top: 0.5em;
    display: block;
    font-size: 13px;
    color: darken($info, 10);
  }
  .form-required-field-message-wrapper{
    background-color: rgba($info, .1);
    padding: 1rem;
    color: darken($info, 10);
    border: 1px solid $info;
  }
  .form-button-wrapper{
    text-align: right;
  }
}



.cms_content{
  .plugin-blog{
    .blog-latest-entries{
      display: grid;
      gap: 1rem;
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
      article:not(.event){
        border: 1px solid #eee;
        padding: 1rem;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        a{
          text-decoration: none;
        }
        h3{
          line-height: 1.2;
          font-size: 20px;
          a{
            color: inherit;
            text-decoration: none;
          }
        }
        .blog-visual{
          margin-bottom: 10px;
          img{
            max-width: 100%;
            height: auto;
          }
        }
        .post__categories{
          display: flex;
          flex-wrap: wrap;
          gap: .25rem;
          padding-left: 0;
          list-style-type: none;
          margin-bottom: 5px;
          font-size: 90%;
          font-weight: bold;
        }
      }
      article.event{
        position: relative;
        padding: 0;
        .event__content{
          position: absolute;
          bottom: 20px;
          left: 10px;
          right: 10px;
          padding: 20px;
          background-color: $dark;
          color: white;
          text-align: center;
          h3{
            a{
              text-decoration: none;
            }
          }
        }
        .date-range{
          margin-bottom: 10px;
          font-family: $text_medium;
          display: flex;
          gap: .2rem;
          justify-content: center;
        }
      }
    }
  }
}

.wrapper {
  h1,h2,h3,h4,h5 {
    &.accordion-header::after {
      display: none;
    }
  }
}


.agenda_event__date{
  font-size: 2rem;
  font-family: $text_black;
  color: $primary;
  display: flex;
  vertical-align: middle;
  gap: 1rem;
  margin-bottom: 1rem;
  span{
    display: inline-block;
    margin-right: 10px;
  }
}