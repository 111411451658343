
/* Common style */
figure.figure {
	position: relative;
	float: left;
	overflow: hidden;
	min-width: 100%;
	max-width: 100%;
	width: 100%;
	background: $dark;
	text-align: center;
	cursor: pointer;
  border-radius: 10px;
  margin: 0;

  &:focus-within {
    outline: solid 4px var(--bs-blue);
  }
}

figure.figure img {
	position: relative;
	display: block;
	height: auto;
	max-width: 100%;
  min-width: 100%;
	opacity: 0.5;
  border-radius: 10px;
}

figure.figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

figure.figure figcaption::before,
figure.figure figcaption::after {
	pointer-events: none;
}

figure.figure figcaption,
figure.figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
figure.figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

figure.figure .title {
  font-family: $text_black;
  padding: 1rem;
}

figure.figure .title span {
	font-weight: 800;
}

figure.figure .title,
figure.figure p {
	margin: 0;
}

figure.figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
  font-family: $text_medium;
}

/* Individual effects */


/*---------------*/
/***** effect-1 *****/
/*---------------*/

figure.effect-1 figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(top, rgba($primary,0) 0%, rgba($primary,0.8) 75%);
	background: linear-gradient(to bottom, rgba($primary,0) 0%, rgba($primary,0.8) 75%);
	content: '';
	opacity: 0;
	-webkit-transform: translate3d(0,50%,0);
	transform: translate3d(0,50%,0);
}

figure.effect-1 .title {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	-webkit-transition: -webkit-transform 0.35s, color 0.35s;
	transition: transform 0.35s, color 0.35s;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

figure.effect-1 figcaption::before,
figure.effect-1 p {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-1 p {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	opacity: 0;
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}

figure.effect-1:hover .title {
	color: #fff;
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
	transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
}

figure.effect-1:hover figcaption::before ,
figure.effect-1:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/*---------------*/
/***** Roxy *****/
/*---------------*/

figure.effect-4 {
	background: -webkit-linear-gradient(45deg, #ff89e9 0%, #05abe0 100%);
	background: linear-gradient(45deg, #ff89e9 0%,#05abe0 100%);
}

figure.effect-4 img {
	max-width: none;
	width: -webkit-calc(100% + 60px);
	width: calc(100% + 60px);
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-50px,0,0);
	transform: translate3d(-50px,0,0);
}

figure.effect-4 figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid #fff;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-20px,0,0);
	transform: translate3d(-20px,0,0);
}

figure.effect-4 figcaption {
	padding: 3em;
	text-align: left;
}

figure.effect-4 .title {
	padding: 30% 0 10px 0;
}

figure.effect-4 p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-10px,0,0);
	transform: translate3d(-10px,0,0);
}

figure.effect-4:hover img {
	opacity: 0.7;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-4:hover figcaption::before,
figure.effect-4:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-5 {
	background: #9e5406;
}

figure.effect-5 img {
	opacity: 0.7;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-5:hover img {
	opacity: 0.4;
}

figure.effect-5 figcaption::before,
figure.effect-5 figcaption::after {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-5 figcaption::before {
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}

figure.effect-5 figcaption::after {
	border-right: 1px solid #fff;
	border-left: 1px solid #fff;
	-webkit-transform: scale(1,0);
	transform: scale(1,0);
}

figure.effect-5 .title {
	padding-top: 30%;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,-20px,0);
	transform: translate3d(0,-20px,0);
}

figure.effect-5 p {
	padding: 20px 2.5em;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,20px,0);
	transform: translate3d(0,20px,0);
}

figure.effect-5:hover figcaption::before,
figure.effect-5:hover figcaption::after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-5:hover .title,
figure.effect-5:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}



/*---------------*/
/***** Oscar *****/
/*---------------*/

figure.effect-2 {
	background: -webkit-linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
	background: linear-gradient(45deg, #22682a 0%,#9b4a1b 40%,#3a342a 100%);
}

figure.effect-2 img {
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-2 figcaption {
	padding: 3em;
	background-color: rgba(58,52,42,0.7);
	-webkit-transition: background-color 0.35s;
	transition: background-color 0.35s;
}

figure.effect-2 figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid #fff;
	content: '';
}

figure.effect-2 .title {
	margin: 20% 0 10px 0;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0);
}

figure.effect-2 figcaption::before,
figure.effect-2 p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0);
	transform: scale(0);
}

figure.effect-2:hover .title {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-2:hover figcaption::before,
figure.effect-2:hover p {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-2:hover figcaption {
	background-color: rgba(58,52,42,0);
}

figure.effect-2:hover img {
	opacity: 0.4;
}

/*---------------*/
/***** Marley *****/
/*---------------*/

figure.effect-6 figcaption {
	text-align: right;
}

figure.effect-6 .title,
figure.effect-6 p {
	position: absolute;
	right: 30px;
	left: 30px;
	padding: 10px 0;
}


figure.effect-6 p {
	bottom: 30px;
	line-height: 1.5;
	-webkit-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0);
}

figure.effect-6 .title {
	top: 30px;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,20px,0);
	transform: translate3d(0,20px,0);
}

figure.effect-6:hover .title {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-6 .title::after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 4px;
	background: #fff;
	content: '';
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
}

figure.effect-6 .title::after,
figure.effect-6 p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-6:hover .title::after,
figure.effect-6:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/*---------------*/
/***** Ruby *****/
/*---------------*/

figure.effect-3 {
	background-color: #17819c;
}

figure.effect-3 img {
	opacity: 0.7;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.15);
	transform: scale(1.15);
}

figure.effect-3:hover img {
	opacity: 0.5;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-3 .title {
	margin-top: 20%;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,20px,0);
	transform: translate3d(0,20px,0);
}

figure.effect-3 p {
	margin: 1em 0 0;
	padding: 3em;
	border: 1px solid #fff;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,20px,0) scale(1.1);
	transform: translate3d(0,20px,0) scale(1.1);
}

figure.effect-3:hover .title {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-3:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0) scale(1);
	transform: translate3d(0,0,0) scale(1);
}


/*---------------*/
/***** Dexter *****/
/*---------------*/

figure.effect-7 {
	background: -webkit-linear-gradient(top, rgba(37,141,200,1) 0%, rgba(104,60,19,1) 100%);
	background: linear-gradient(to bottom, rgba(37,141,200,1) 0%,rgba(104,60,19,1) 100%);
}

figure.effect-7 img {
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-7:hover img {
	opacity: 0.4;
}

figure.effect-7 figcaption::after {
	position: absolute;
	right: 30px;
	bottom: 30px;
	left: 30px;
	height: -webkit-calc(50% - 30px);
	height: calc(50% - 30px);
	border: 7px solid #fff;
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,-100%,0);
	transform: translate3d(0,-100%,0);
}

figure.effect-7:hover figcaption::after {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-7 figcaption {
	padding: 3em;
	text-align: left;
  display: block;
}

figure.effect-7 p {
	position: absolute;
	right: 60px;
	bottom: 60px;
	left: 60px;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-100px,0);
	transform: translate3d(0,-100px,0);
}

figure.effect-7:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}


/*---------------*/
/***** Chico *****/
/*---------------*/

figure.effect-8 img {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.12);
	transform: scale(1.12);
}

figure.effect-8:hover img {
	opacity: 0.5;
	-webkit-transform: scale(1);
	transform: scale(1);
}

figure.effect-8 figcaption {
	padding: 3em;
}

figure.effect-8 figcaption::before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 1px solid #fff;
	content: '';
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

figure.effect-8 figcaption::before,
figure.effect-8 p {
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

figure.effect-8 .title {
	padding: 20% 0 20px 0;
}

figure.effect-8 p {
	margin: 0 auto;
	max-width: 200px;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}

figure.effect-8:hover figcaption::before,
figure.effect-8:hover p {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
