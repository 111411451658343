$fa-font-path: "../fonts/fontawesome";
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$min-contrast-ratio: 3;
$primary: #CF292C;
$secondary: #4BBBC4;

$theme-colors: (
  "primary":    $primary,
  "secondary":    $secondary,
);

$btn-border-radius: 2rem;
$btn-padding-y:  .75rem;
$btn-padding-x:     2rem ;





.header__site .top__bar{
  background-color: #f3F3F3 !important;
}

.header__site .btn--search svg{
  stroke: #fff !important;
}


.top__bar{
  flex-wrap: wrap;
}