:root {
  --brochure-group-gap: 1em;
}

.brochure-row {
  display: grid;
  gap: var(--brochure-group-gap);
  margin-bottom: 10px;
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-only(sm) {
    grid-template-columns: 1fr;
  }
}

.brochure-default.brochure-item {
  border: 1px solid #ddd;
  padding: .5em;

  display: flex;
  flex-direction: column;
  min-width: calc(25% - 3/4 * var(--brochure-group-gap));

  .brochure-link {
    @extend .btn;
    @extend .btn-primary;

    &:not(:last-child) {
      margin-right: .5em;
    }
  }

  .brochure-caption {
    margin-top: 1em;
  }

  .brochure-informations {
    text-align: center;
  }

  .brochure-title {
    font-family: $text-medium;
    font-size: 1.2em;
    font-weight: normal;
  }

  .brochure-cover img {
    width: 100%;
    height: auto;
  }
}
.brochure__item{
  margin-bottom: 1rem;
  .last{
    max-width: 60px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  h3{
    font-size: 80%;
    text-transform: uppercase;
  }
  a{
    border-width: 2px;
    color: inherit;
    font-weight: bold;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 90%;
    small{
      font-size: 80%;
    }

  }
}
.brochure-button.brochure-item {
  &.d-flex{
    gap: .5rem;
  }

  & + .brochure-item{
    margin-top: 10px;
    @include media-breakpoint-down(sm) {
      margin-top: 0 !important;
    }
  }
}
