footer.footer__Site{
  background-color: $secondary;
  color: white;
  padding-top: 3rem;
  .container a{
    color: #fff;
    text-decoration: none;
  }
  h2{
    text-transform: uppercase;
    position: relative;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 3cqmin;
    }
  }
  h3{
    text-transform: uppercase;

    font-family: $text_black;
    font-size: 18px;
  }
  .social__links{
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 1rem;
    a{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      color: $primary;
      border:2px solid;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all .3s ease;
      &:hover{
        background-color: $primary;
        color: white;

        .icon-illiwap {
          background-position: right;
        }
      }
    }
    .icon-illiwap {
      background: url("../img/icon-illiwap.svg") left no-repeat;
      background-size: 40px;
      width: 20px;
      height: 20px;
    }
  }
  .footer__bottom{
    margin-top: 3rem;
    background-color: white;
    color: $dark;
    a{
      color: inherit;
      text-decoration: none;
      display: inline-block;
      &:hover{
        font-weight: bold;
      }
    }
    .nav{
      @include media-breakpoint-up(lg) {
        gap: 1rem;
        li{
          padding: 1rem;
        }
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        li{
          border-bottom: 1px solid #eee;
          padding: 0.5rem;
          text-align: center;
        }
      }
    }
  }
}
