.header__site{
  @include media-breakpoint-up(xl) {

    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    & + main{
      padding-top: 166px;
    }
  }
  @include media-breakpoint-down(lg) {
    position: relative;
  }

  .header__wrapper{
    padding: 10px;
    font-family: $text_medium;

    align-items: center;
    background-color: white;
    z-index: 99;
    position: relative;
    @include media-breakpoint-down(lg) {

    }
    @include media-breakpoint-down(md) {
      padding: 1rem;
      #nav__logo{
        img{
          max-width: 130px;
          height: auto;
        }
      }
    }
    @include media-breakpoint-only(lg) {
      #nav__logo{
        img{
          max-width: 120px;
          height: auto;
        }
      }
    }
  }

  .top__bar{
    padding: 10px 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    a, .contact__link{
      color: inherit;
      font-size: 80%;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: $text_medium !important;
      text-decoration: none;
      font-weight: bold;
    }
    .dropdown-menu{
      border-radius: 0;
      a{
        padding-top: 8px;
        padding-bottom: 8px;
        &:hover{
          background-color: rgba($primary, .1);
        }
      }
    }
    .dropdown{
      >a.dropdown-toggle{
        font-weight: bold;
        color: $dark;
        text-decoration: none;
      }
    }
    @include media-breakpoint-down(md) {
      .contact__link{
        display: flex;
      }
    }
    @include media-breakpoint-down(sm) {
      .dropdown-menu{
        width: 100%;
        min-width: 280px;
        .dropdown-item{
          white-space: inherit;
        }
      }
    }
  }
  ul{
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }
  nav.nav{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
    text-align: center;
    &__last{
      gap: .3rem;
      a{
        text-transform: uppercase;
        text-decoration: none;
      }
      @include media-breakpoint-only(lg) {
        // position: absolute;
      }
    }

  }
  .menu__nav{
    display: flex;
    gap: 1rem;
    .nav__link{
      text-transform: uppercase;
      color: $dark;
      text-decoration: none;
      box-shadow: inset 0 0 0 0 $primary;
      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
      display: inline-block;
      padding: 0.3rem .5rem;
      &:hover {
        @include media-breakpoint-up(lg) {
          box-shadow: inset 200px 0 0 0 $primary;
          color: white;
        }
      }
    }
    .nav__item {
      position: relative;
      @include media-breakpoint-only(lg) {
        &:nth-child(n+4) {
          display: none;
        }
      }
      .submenu{
        position: absolute;
        left: 50%;
        top: 150%;
        min-width: 250px;
        text-align: left;
        background: #FFFFFF;
        border: 1px solid #F9F9F9;
        box-shadow: 0 2px 18px 0 rgba(46,46,46,0.13);
        border-radius: 4px;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        border-bottom: 5px solid $primary;
        transform: translateX(-50%);
        a{
          display: block;
          padding: .5rem 1rem;
          text-align: center;
          text-transform: uppercase;
          font-size: 14px;
          font-family: $text_medium;
          color: $dark;
          text-decoration: none;
          transition: all .3s ease;
          &:hover{
            background-color: $primary;
            color: white;
          }
        }
      }
      &:hover, &.active, &.ancestor{
        .nav__link{
          @include media-breakpoint-up(lg) {
            box-shadow: inset 200px 0 0 0 $primary;
            color: white;
          }
          @include media-breakpoint-down(sm) {
            background-color: $primary;
            color:white;
          }
        }
      }
      &:hover{
        .submenu{
          top: 100%;
          opacity: 1;
          visibility: visible;
        }

      }
    }
  }
  .contact__link{
    @include media-breakpoint-only(lg) {
      span{
        display: none;
      }

    }
    @include media-breakpoint-up(sm) {

      display: inline-flex;
      align-items: center;
      position: relative;
      .icon__contact{
        display: inline-block;
        width: 31px;
        height: 33px;
        background-image: url('../img/contact.svg');
        background-size: cover;
        transform: translateY(-2px);
        margin-right: .5rem;
      }
    }
  }
  .hamburger{
    @include media-breakpoint-up(lg) {
      display: none;
    }
    background-color: $primary;
    height: 40px;
    width: 40px;
    .hamburger-box{
      transform: scale(.5);
    }

  }
  .btn--search{
    background-color: transparent;
    border: 0;
    color: $dark;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: all .3s ease;
    background-color: $primary;
    svg{
      stroke: $dark;
      transition: all .3s ease;
    }
    &:hover{
      background-color: darken($primary, 10);
      svg{
        stroke: $white;
      }
    }
  }
  .search__bar{
    @include media-breakpoint-up(sm) {

      position: absolute;
      left: 0;
      right: 0;
      z-index: -10;
      overflow: auto;
      max-height: calc(100vh - 80px);
      transform: translateY(calc(-100% - 8px));
      background-color: $secondary;
      border-bottom: 1px solid #ccc;
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: white;
      text-align: center;
      pointer-events: none;
      transition: all .6s cubic-bezier(0.86,0,0.07,1);
      opacity: 0;
      h2{
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      input{
        padding: .5rem 1rem;
        border: 0;
        border-bottom: 2spx solid lighten($primary, 20);;
        transition: all .3s ease;
        &[type="search"]{
          padding: .5rem 1rem;
          background-color: white;
        }
        &[type="submit"]{
          background-color: $primary;
          color: white;
          &:hover{
            background-color: lighten($primary, 6);
          }
        }
      }
      &.is-open, &:focus-within {
        transform: translateY(0);
        z-index: 10;
        pointer-events: all;
        opacity: 1;
      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &.header-scroll{
    @include media-breakpoint-up(lg) {
      position: fixed;
      z-index: 1;
      left: 0;
      right: 0;
    }
  }

}



@include media-breakpoint-down(lg) {
  .header__site{
    #btn-search{
      display: none;
    }
    .hamburger{
      position: absolute;
      top: 25px;
      right: 10px;
    }
    .nav.nav{
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
    .nav__wrp, nav.nav__last{
      display: none ;
      width: 100%;
    }
    .contact__link{
      display: flex;
      padding: 0.5rem;
      padding-top: 0.7rem;
      color: inherit;

      i{
        display: none;
      }
    }
    .menu__nav{
      flex-direction: column;
      gap: 0;
      width: 100%;
      li{
        width: 100%;
        border-bottom: 1px solid #eee;
        &:not(:last-child){
        }
        a.nav__link{
          padding:.5rem;
          display: block;

        }
        &.active, &.ancestor{
          a{
            background-color: $primary;
            color: white;
          }
        }
        .submenu{
          display: none;
        }

      }
    }
    .search__bar{
      padding: 1rem;
      background-color: darken(white, 5);
      h2{
        display: none;
      }
      form{
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        input{
          padding: 0.8rem;
          flex: 1;
          display: block;
          &[type="search"]{
            border: 1px solid #eee;
            margin-bottom: 10px;
          }
          &[type="submit"]{
            background-color: $primary;
            color: white;
            border: 0;
          }
        }
      }
    }
  }
  .menu_is_visible{
    .header__site{
      .header__wrapper, .search__bar{
        display: block;
      }
      .nav__wrp, .nav__last{
        display: block;
      }
    }
  }
}
