
.menu_wrapper{
  @include media-breakpoint-up(lg) {
    margin-bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem;
  }

  &:not([data-slide]){
    display: grid;
    gap: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
    grid-template-columns: repeat( 5, 1fr );
    &.three{
      grid-template-columns: repeat( 3, 1fr );
    }
    &.four{
      grid-template-columns: repeat( 4, 1fr );
    }
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat( 3, 1fr ) !important;
    }
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 1fr !important;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr !important;
    }
  }
  figure.figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .slick-arrow{
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    transition: all .3s ease;
    &.slick-prev{
      left:-50px !important;
    }
    &.slick-next{
      right:-50px !important;
    }
    &:hover{
      background-color: $primary;
      &:before, &::after{
        color: white !important;

      }
    }
  }
  .slick-prev:before{
    content:"\f060";
  }
  .slick-next:before{
    content:"\f061";
  }
  .slick-dots{
   bottom: 0px;
   li{
      button{
        &::before{
          content: "";
          background-color: #333;
          border-radius: 10px;
        }
      }
      &.slick-active{
        button{
          &::before{
            background-color: $primary;
          }
        }
      }
    }
  }

  .slick-slide >div{
    padding: 1rem;
  }
  .slick-arrow{
    &.slick-next,
    &.slick-prev{
      &::before{
        color: $primary;
        font-family: "Font Awesome 6 Free"; font-weight: 900;
      }
    }
    &.slick-next{
      right: -40px;
    }
    &.slick-prev{
      left: -40px;
    }
  }
  &[data-slide]{
    .menu__item  {
      padding: .5rem;
    }
  }
}
