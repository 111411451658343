html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}

body{
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  font-family: $text;
  text-rendering: auto;
  transition: transform .3s ease;
  max-width: 100%;
  line-height: 1.2;
  scroll-padding-top: 130px;
}
main{
  flex: 1 0 auto;

}
#footer {
  flex-shrink: 0;
}

.btn{
  &-primary{
    color: white;
  }
  &-light{
    background-color: white;
  }
  &-dark{
    background-color: $dark;
    color: white;
  }
  &-danger{
    background-color: $danger;
    color: white;
  }
  &-outline-danger{
    border: 1px solid $danger;
    color: $danger;
  }
  &-outline-light{
    border: 1px solid $light;
    color: $light;
  }
  &-outline-dark{
    border: 1px solid $dark;
    color: $dark;
  }
}

.breadcrumb{
  .breadcrumb-item{
    display: inline-flex;
    align-items: center;
    a{
      font-weight: bold;
      text-decoration: none;
    }
  }
  .breadcrumb-item+.breadcrumb-item::before{
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f054";
    color: $dark;
    font-size: 80%;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
}

.cms-modules-page-module{
  margin-bottom: 30px;
}

.plugin__search{
  display: grid;
  gap: 1rem;
  @include media-breakpoint-up(md) {
    grid-template-columns: 400px 1fr;

  }
  @include media-breakpoint-up(sm) {
    grid-template-columns: 230px 1fr;

  }
  .frm__search{
    border: 1px solid rgba($primary, .4);
    background-color: rgba($primary, .2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    input, button{
      border-radius: 0;
      display: block;
      width: 100%;
      padding: 0.75rem;
      border: 0;

    }
    input{
      margin-bottom: 10px;
    }
    button{
      background-color:$primary;
      color: white;
      &:hover{
        background-color: darken($primary, 2);
      }
    }
  }
  .search-results{
    ul.search-results__list{
      list-style: none;
      padding-left: 0;
      margin-bottom: 2rem;
      h3{
        font-family: $text_medium;
        font-size: 18px;
        text-transform: uppercase;
        a{
          // color: inherit;
        }
      }
      li{
        padding: 30px;
        // box-shadow: rgba(0, 0, 0, 0.07) 0px 25px 20px -20px;
        // box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        border: 1px solid #eee;
        &:nth-child(even){
          background-color: rgba($primary, .04);
          border-top: 0;
        }
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .highlighted{
      // background-image: linear-gradient(-100deg, rgba(255, 255, 255, 0), yellow 85%, rgba(255, 255, 255, 0));
      display: inline-block;
      color: black;
      text-decoration: none;
      background-position-y: -0%;
      background-image: linear-gradient( white 50%, gold 50%);
      transition: background 500ms ease;
      background-size: 2px;
      background-size: auto 135%;
      font-weight: bold;
      &:hover{
        background-position-y: 100%;
      }
    }
  }
}
.event__visual {
  min-height: 200px;
}

.posts__wrapper  {
  .blog__list.agenda__list{
    article.event{
      position: relative;
      padding: 0;
      .event__visual {
        a{
          display: block;
        }
      }
      .event__content{
        @include media-breakpoint-up(xl) {
          position: absolute;
          bottom: 20px;
          left: 10px;
          right: 10px;
          padding: 20px;
          background-color: $dark;
          color: white;
          text-align: center;
        }

        @include media-breakpoint-down(xl) {
          text-align: center;
          padding: 2rem;
          .event__date{
            color: $primary;
          }
        }
      }
      .date-range{
        margin-bottom: 10px;
        font-family: $text_medium;
        display: flex;
        gap: .2rem;
        justify-content: center;
      }
    }
  }
}
.agenda-archive, .blog-archive {
  .month { margin-left: 1rem; }
}

.col{
  @include media-breakpoint-only(xs) {
    min-width: 100%;
    margin-bottom: 20px;
  }
}

.cke_panel_block,
.cke_panel_block h1 {
  font-family: sans-serif;
}

.bg-primary{
  a:not([class]) {
    color: $secondary;
    text-decoration: underline;
    &:hover{
      color: darken($primary, 30);
    }
  }
  .btn-primary{
    background-color:darken($primary,10);
  }
  .btn-outline-primary{
    border-color: darken($primary,20);
    color: darken($primary, 30);
  }
  .btn-danger{
    &:hover{
      background-color:darken($danger,20);
    }
  }
  .btn-light{
    &:hover{
      background-color:darken($light,20);
    }
  }
  .btn-dark{
    &:hover{
      background-color:darken($dark,20);
    }
  }
}
.bg-secondary{
  a:not([class]) {
    color: $primary;
    text-decoration: underline;
    &:hover{
      color: darken($primary, 30);
    }
  }
  .btn-secondary{
    background-color:darken($secondary,10);
    &:hover{
      background-color:darken($secondary,30);
      color: lighten($secondary,30);
    }
  }
  .btn-outline-secondary{
    border-color: darken($secondary,20);
    color: darken($secondary, 30);
  }
  .btn-danger{
    &:hover{
      background-color:darken($danger,20);
    }
  }
  .btn-light{
    &:hover{
      background-color:darken($light,20);
    }
  }
  .btn-dark{
    &:hover{
      background-color:darken($dark,20);
    }
  }
}






.breadcrumb{
  .breadcrumb-item + .breadcrumb-item {
    &::before{
      content: "/";
    }
  }
  li{
    @extend .breadcrumb-item;
  }
}

#id_legalnotice,
#id_error_page{
  .header__site{
    border-bottom: 1px solid darken($white, 13);
  }
  main{
    margin-top: 2rem;
  }

}
#id_error_page{
  background-image: linear-gradient(90deg, $primary 10%, darken($primary, 15) 90%);
  main{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10vh;
    .container{
      h1{
        font-size: 4vw;
      }
      p{
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      .btn{
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.legalnotice-list{
  list-style-type: none;
  padding-left: 0;
  >li{
    background-color: darken($white, 3);
    padding: 2rem;
    margin-bottom: 2rem;
    h2{
      font-size: 20px;
    }
  }
}


#sitemap{
  margin-bottom: 3rem;
  ul{
    position: relative;
    margin: 0 0 0 30px;
    list-style-type: none;
    display: block;
    margin-left: 0;
    li{
      margin-bottom: 10px;
    }

    a{
      background-color: $dark;
      color: white;
      display: inline-block;
      padding: 0 15px 0 15px;
      line-height: 40px;
      text-decoration: none;
      border-radius: 8px;
      transition: all .3s ease;
      &:hover{
        background-color: $primary;
      }
    }


    ul{
      padding-top: 10px;
      margin: 0 0 0 30px;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 10px;
        background-color: #c8cdd2;
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        margin-top: -20px;
        background-color: #c8cdd2;
        z-index: -1;
      }
      li{
        position: relative;
        margin-top: 10px;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          top: 20px;
          left: -32px;
          width: 50px;
          height: 2px;
          background-color: #c8cdd2;
        }
      }
    }
  }
}

.btn + .btn{
  @include media-breakpoint-down(sm) {

  }
}

legend {
  float: none;
  font-size: 1em;
}

.lightbox-container {
  position: relative;
  display: block;

  .photo-zoom-button {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
}