.img-thumbnail-wrapper {
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.img-author-icon {
  @extend .fas;
  font-size: 12px;
  bottom: 0;
  color: $white;
  cursor: pointer;
  padding: .5em;
  position: absolute;
  right: 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, .5);
  z-index: 98;

  &::before {
    content: fa-content($fa-var-info-circle);
  }

  &:hover,
  &:focus {
    color: theme-color('red');
  }
}
