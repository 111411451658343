@font-face {
  font-family:"Gotham-Book";
  src:url("../fonts/gotham/Gotham-Book.woff2") format("woff2"),
  url("../fonts/gotham/Gotham-Book.woff") format("woff"),
  url("../fonts/gotham/Gotham-Book.otf") format("opentype");
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"Gotham-Medium";
  src:url("../fonts/gotham/Gotham-Medium.woff2") format("woff2"),
  url("../fonts/gotham/Gotham-Medium.woff") format("woff"),
  url("../fonts/gotham/Gotham-Medium.otf") format("opentype");
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"Gotham-Ultra";
  src:url("../fonts/gotham/Gotham-Ultra.woff2") format("woff2"),
  url("../fonts/gotham/Gotham-Ultra.woff") format("woff"),
  url("../fonts/gotham/Gotham-Ultra.otf") format("opentype");
  font-style:normal;
  font-weight:400;
}

$text : "Gotham-Book";


$text_medium : "Gotham-Medium";


$text_black : "Gotham-Ultra";
h1{
  font-family: $text_black;
}
h2, h3, h4, h5, h6{
  font-family: $text_medium;
}

h2{
  font-size: 30px;
}
h3{
  font-size: 22px;
}